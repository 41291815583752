#__react-alert__ span {
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.MuiTypography-root{
    overflow-wrap: anywhere !important;
}

body::-webkit-scrollbar {
    display: none;
}

body::-webkit-scrollbar-track {
    display: none;
}

body::-webkit-scrollbar-thumb {
    display: none;
}

.list-group-item {
    .card-title {
      cursor: pointer;
    }
    .card-collapse {
      overflow: hidden;
      transition: height 0.3s ease-out;
    }
        h2 {margin: 0;}
  }